<template>
  <div :class="{ relative: setRootPosition }">
    <Badge :class="[computedSize, colorClass, { '-scale-x-100': flip }]" />
    <span
      class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 whitespace-nowrap font-title text-white"
      :class="textClass"
      :style="{ fontSize: computedFontSize }"
      >{{ campaignText }}
    </span>
  </div>
</template>

<script setup lang="ts">
import Badge from '@brand/assets/images/campaign.svg?component';

type Size = 'sm' | 'md' | 'lg';

const props = defineProps({
  campaignText: {
    type: String,
    default: '',
  },
  size: {
    type: String as PropType<Size>,
    default: 'md',
  },
  colorClass: {
    type: String,
    default: 'bg-supporting-price',
  },
  textClass: {
    type: String,
    default: '',
  },
  setRootPosition: {
    type: Boolean,
    default: true,
  },
  flip: {
    type: Boolean,
    default: false,
  },
  responsive: {
    type: Boolean,
    default: true,
  },
});

const sizeClasses: {
  static: Record<Size, string>;
  responsive: Record<Size, string>;
} = {
  static: {
    sm: 'w-20',
    md: 'w-24',
    lg: 'w-28',
  },
  responsive: {
    sm: 'w-16 md:w-20',
    md: 'w-20 md:w-24',
    lg: 'w-24 md:w-28',
  },
};

const fontSizes: Record<Size, number> = {
  sm: 28,
  md: 34,
  lg: 40,
};

const computedFontSize = computed(() => {
  const baseSize = fontSizes[props.size];
  const fontSize = baseSize - props.campaignText.length * 2;
  return `${fontSize}px`;
});

const computedSize = computed(() => {
  if (!props.responsive) {
    return sizeClasses.static[props.size];
  }

  return sizeClasses.responsive[props.size];
});
</script>
